import React, { useState, useEffect } from "react";
import DataField from "../../utils/DataField";
import { titleCase, clientAge, checkNested, translateGender, formatAsMoney, formatPhone, formatAddress, getProperMetadata, retrieveReceitaFederalStatus, getIndicatorsFlagIcon } from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { Table } from "@mui/material";
import ValidatedImage from "../../utils/ValidatedImage"
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { Map } from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles(() => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: '#6F6F6F',
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    }
  }));
    
function ClientProfile(props) {
    const decision_metadata = getProperMetadata(props.natural_person.analysis_status_events);
    const registration_data = decision_metadata.registration_data;
    let score_unico = ((props.natural_person.custom_data || {}).unico_response || {}).score;

    if (!score_unico) {
        score_unico = (props.natural_person.custom_data || {}).score_unico;
    }

    const classes = useStyles();

    const serasaEstimatedIncome = []
    if (decision_metadata.serasa) {
        if (decision_metadata.serasa.estimated_income) {
            serasaEstimatedIncome.push(decision_metadata.serasa.estimated_income)
        } else {
            for (let i = 0; i < Object.keys(decision_metadata.serasa).length; i++) {
                if ((decision_metadata.serasa[Object.keys(decision_metadata.serasa)[i]] || {}).estimated_income) {
                    serasaEstimatedIncome.push(decision_metadata.serasa[Object.keys(decision_metadata.serasa)[i]].estimated_income);
                }
            }
        }
    }

    const { t } = useTranslation();

    const retrieveReceitaFederalData = (person) => {
        if (person && person.analysis_status_events) {
            if (Array.isArray(person.analysis_status_events) && person.analysis_status_events.length > 0) {
                for (let i = person.analysis_status_events.length - 1; i >= 0; i--) {
                    if (person.analysis_status_events[i] && person.analysis_status_events[i].decision_metadata) {
                        if (person.analysis_status_events[i].decision_metadata.receita_federal_status) {
                            return person.analysis_status_events[i].decision_metadata.receita_federal_status
                        }
                    }
                }
            }
        }
        return null;
    }

    const [receitaFederalData, setReceitaFederalData] = useState(null);

    useEffect(() => {
        setReceitaFederalData(retrieveReceitaFederalData(props.natural_person));
    }, [props.natural_person])

    const retrieveValidationStatus = (flag) => {

        if (flag === 'positive') {
            return (
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Tooltip placement="right" title="Dado validado em fontes externas">
                        <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                            Validado
                        </div>
                    </Tooltip>
                </div>
            );
        }
        if (flag === 'negative') {
            return (
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Tooltip placement="right" title="Dado divergente de fontes externas">
                        <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                            Divergente
                        </div>
                    </Tooltip>
                </div>
            );
        }        return (
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Tooltip placement="right" title="Dado ausente em fontes externas">
                    <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ffe5a899", color: "#db9a00", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                        Inconclusivo
                    </div>
                </Tooltip>
            </div>
        );
    }

    const retrieveAddressValidationStatus = (list, original) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].street === original.street && list[i].number === original.number && list[i].city === original.city && list[i].uf === original.uf && list[i].country === original.country && list[i].postal_code === original.postal_code && list[i].neighborhood === original.neighborhood) {
                return retrieveValidationStatus(list[i].flag);
            }
        }
        return retrieveValidationStatus(null);
    }

    const retrieveEmailValidationStatus = (list, original) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].email === original) {
                return retrieveValidationStatus(list[i].flag);
            }
        }
        return retrieveValidationStatus(null);
    }

    const retrievePhoneValidationStatus = (list, original) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].number === original.number && list[i].area_code === original.area_code) {
                return retrieveValidationStatus(list[i].flag);
            }
        }
        return retrieveValidationStatus(null);
    }

    const [expandPhone, setExpandPhone]     = useState(1);
    const [expandEmail, setExpandEmail]     = useState(1);
    const [expandAddress, setExpandAddress] = useState(1);

    const renderExpansor = (value, setFunction) => {
        if (value === 1) return (
            <div onClick={setFunction} style={{ cursor: "pointer", padding: 0, marginTop: 12, display: "flex", height: "fit-content", flexDirection: "row", alignItems: "center"}}>
                <AddIcon style={{width: 24, height: 24, color: "grey"}} />
                <p style={{fontSize: 12, margin: 0, marginTop: 4, color: "grey"}}>Expandir</p>
            </div>
        )
        return (
            <div onClick={setFunction} style={{ cursor: "pointer", padding: 0, marginTop: 12, display: "flex", height: "fit-content", flexDirection: "row", alignItems: "center"}}>
                <RemoveIcon style={{width: 24, height: 24, color: "grey"}} />
                <p style={{fontSize: 12, margin: 0, marginTop: 4, color: "grey"}}>Minimizar</p>
            </div>
        )
    }

    const renderDataFields = () => {
        
        if (!registration_data || Object.keys(registration_data).length < 1) {
            return (
                <div style={{width: '100%', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p style={{color: '#6f6f6f'}}>{t('no_bureau_data')}</p>
                </div>
            );
        }

        const filteredPhones = []
        if ((registration_data.phone_number || []).length > 0) {
            for (let i = 0; i < registration_data.phone_number.length; i++) {
                if (registration_data.phone_number[i].flag !== 'negative' || registration_data.phone_number[i].description === 'Telefone proveniente da base de dados') {
                    filteredPhones.push(registration_data.phone_number[i])
                }
            }
        }

        const filteredEmails = []
        if ((registration_data['e-mail'] || []).length > 0) {
            for (let i = 0; i < registration_data['e-mail'].length; i++) {
                if (registration_data['e-mail'][i].flag !== 'negative' || registration_data['e-mail'][i].description === 'E-mail - E-mail proveniente da base de dados.') {
                    filteredEmails.push(registration_data['e-mail'][i])
                }
            }
        }

        const filteredAddresses = []
        if ((registration_data.address || []).length > 0) {
            for (let i = 0; i < registration_data.address.length; i++) {
                if (registration_data.address[i].flag !== 'negative' || registration_data.address[i].description === 'Endereço proveniente de base de dados.') {
                    filteredAddresses.push(registration_data.address[i])
                }
            }
        }

        return (
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                {registration_data.name?.description &&
                        <DataField
                            title={"Nome"}
                            label={titleCase(registration_data.name.description.split('alidado: ')[registration_data.name.description.split('alidado: ').length - 1], "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {registration_data.mother_name?.description &&
                        <DataField
                            title={"Nome da Mãe"}
                            label={titleCase(registration_data.mother_name.description.split('alidado: ')[registration_data.mother_name.description.split('alidado: ').length - 1], "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {registration_data.nationality?.description &&
                        <DataField
                            title={"Nacionalidade"}
                            label={registration_data.nationality.description || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {checkNested(registration_data, "gender", "description") &&
                        <DataField
                            title={"Gênero"}
                            label={translateGender(registration_data.gender.description) || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {registration_data.birthdate?.description &&
                        <DataField
                            title={"Data de Nascimento"}
                            label={clientAge(registration_data.birthdate.description, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {(filteredPhones || []).length > 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Telefones:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {filteredPhones.map((phone, index) => (
                                        <>
                                            {expandPhone > index &&
                                                <TableRow className={'odd'} style={{margin: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}><div style={{padding: "0"}}>{formatPhone(phone)}</div></TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {filteredPhones.length > 1 && renderExpansor(expandPhone, () => {
                            if (expandPhone !== 1) setExpandPhone(1)
                            else setExpandPhone(filteredPhones.length)
                        })}
                    </div>
                }
                {(filteredEmails || []).length > 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Emails:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {filteredEmails.map((email, index) => (
                                        <>
                                            {expandEmail > index &&
                                                <TableRow className={'odd'} style={{margin: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}><div style={{padding: "0"}}>{email['email']}</div></TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {filteredEmails.length > 1 && renderExpansor(expandEmail, () => {
                            if (expandEmail !== 1) setExpandEmail(1)
                            else setExpandEmail(filteredEmails.length)
                        })}
                    </div>
                }
                {(filteredAddresses || []).length > 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Endereços:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {filteredAddresses.map((address, index) => (
                                        <>
                                            {expandAddress > index &&
                                                <TableRow key={index} className={'odd'} style={{margin: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}>{formatAddress(address, '-')}</TableCell>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}>
                                                        <Tooltip title={t('go_to_map')} placement="right">
                                                            <a
                                                                href={"https://www.google.com/maps/search/?api=1&query=" + formatAddress(address, "-").replace(/ /g, "%20").replace(/,/, '%2C')}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Map style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777"}} />
                                                            </a>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {filteredAddresses.length > 1 && renderExpansor(expandAddress, () => {
                            if (expandAddress !== 1) setExpandAddress(1)
                            else setExpandAddress(filteredAddresses.length)
                        })}
                    </div>
                }
            </div>
        )
    }

    const renderComparativeTable = (originalData, bureauData) => {

        const getTableRowClass = (type) => {
            const order = ['header']
            if (originalData.name) order.push('name');
            if (originalData.mother_name) order.push('mother_name');
            if (originalData.nationality) order.push('nationality');
            if (originalData.gender) order.push('gender');
            if (originalData.birthdate) order.push('birth_date');
            if (originalData.phones) order.push('phone_number');
            if (originalData.emails) order.push('email');
            if (originalData.address) order.push('address');
            
            const index = order.indexOf(type);
            if (index === -1) {
                return null;
            }
            return index % 2 === 0 ? 'even' : 'odd';
        }

        if (!originalData.name &&
            !originalData.mother_name &&
            !originalData.nationality &&
            !originalData.gender &&
            !originalData.birthdate &&
            !originalData.phones &&
            !originalData.emails &&
            !originalData.address) return (
                <div style={{width: '100%', padding: "16px", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p style={{color: '#6f6f6f'}}>Sem dados enviados para validação</p>
                </div>
            );
        return (
            <div>
                <Paper className={classes.tableStyle}>
                    <Table>
                        <TableHead className={getTableRowClass('header')}>
                            <TableCell className={classes.tableText}><b>Tipo de campo</b></TableCell>
                            <TableCell className={classes.tableText}><b>Valor fornecido</b></TableCell>
                            {bureauData &&
                                <TableCell className={classes.tableText}><b>Situação</b></TableCell>
                            }
                        </TableHead>
                        <TableBody>
                            {originalData.name &&
                                <TableRow className={getTableRowClass('name')}>
                                    <TableCell className={classes.tableText}>{t('name')}</TableCell>
                                    <TableCell className={classes.tableText}>{originalData.name}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveValidationStatus((bureauData?.name?.flag || ''))}</TableCell>
                                    }
                                </TableRow>
                            }
                            {originalData.mother_name &&
                                <TableRow className={getTableRowClass('mother_name')}>
                                    <TableCell className={classes.tableText}>{t('mother_name')}</TableCell>
                                    <TableCell className={classes.tableText}>{originalData.mother_name}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveValidationStatus((bureauData?.mother_name?.flag || ''))}</TableCell>
                                    }
                                </TableRow>
                            }
                            {originalData.nationality &&
                                <TableRow className={getTableRowClass('nationality')}>
                                    <TableCell className={classes.tableText}>{t('nationality')}</TableCell>
                                    <TableCell className={classes.tableText}>{t(originalData.nationality)}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveValidationStatus((bureauData?.nationality?.flag || ''))}</TableCell>
                                    }
                                    </TableRow>
                            }
                            {originalData.gender &&
                                <TableRow className={getTableRowClass('gender')}>
                                    <TableCell className={classes.tableText}>{t('gender')}</TableCell>
                                    <TableCell className={classes.tableText}>{t(originalData.gender)}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveValidationStatus((bureauData?.gender?.flag || ''))}</TableCell>
                                    }
                                </TableRow>
                            }
                            {originalData.birthdate &&
                                <TableRow className={getTableRowClass('birth_date')}>
                                    <TableCell className={classes.tableText}>{t('birthdate')}</TableCell>
                                    <TableCell className={classes.tableText}>{clientAge(originalData.birthdate)}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveValidationStatus(bureauData?.birthdate?.flag)}</TableCell>
                                    }
                                </TableRow>
                            }
                            {(originalData.phones || []).length > 0 &&
                                <TableRow className={getTableRowClass('phone_number')}>
                                    <TableCell className={classes.tableText}>{t('phone_number')}</TableCell>
                                    <TableCell className={classes.tableText}>{formatPhone(originalData.phones[0])}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrievePhoneValidationStatus(((bureauData || {}).phone_number || []), originalData.phones[0])}</TableCell>
                                    }
                                </TableRow>
                            }
                            {(originalData.emails || []).length > 0 &&
                                <TableRow className={getTableRowClass('email')}>
                                    <TableCell className={classes.tableText}>{t('email')}</TableCell>
                                    <TableCell className={classes.tableText}>{originalData.emails[0].email}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveEmailValidationStatus(((bureauData || {})['e-mail'] || []), (originalData.emails[0].email || ''))}</TableCell>
                                    }
                                    </TableRow>
                            }
                            {originalData.address &&
                                <TableRow className={getTableRowClass('address')}>
                                    <TableCell className={classes.tableText}>{t('address')}</TableCell>
                                    <TableCell className={classes.tableText}>{formatAddress(originalData.address)}</TableCell>
                                    {bureauData &&
                                        <TableCell className={classes.tableText}>{retrieveAddressValidationStatus((((bureauData || {}).address || []) || []), (originalData.address || ''))}</TableCell>
                                    }
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }

    if (registration_data) {
        return (
            <div className="analysisCard" style={{ height: "100%" }}>
                    <div style={{marginBottom: 12}} className={["blueText", "subtitleSize"].join(" ")}>{t("onboarding_data")} - CPF: {props.natural_person.document_number}</div>
                    {checkNested(props.natural_person, "face", "registration_key") ?
                        <div style={{display: 'flex', flexDirection: 'row', width: "100%", marginTop: 24, marginBottom: "16px"}}>
                            <div style={{flex: 1}}>
                                <div style={{marginBottom: 0}} className={["blueText", "textSize"].join(" ")}>{t('Dados obtidos de fontes externas')}</div>
                                {renderDataFields()}
                                {receitaFederalData &&
                                    <div>
                                        {receitaFederalData.flag === 'negative' &&
                                            <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                                Situação na Receita federal: 
                                                <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                    <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                        {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        }
                                        {receitaFederalData.flag === 'positive' &&
                                            <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                                Situação na Receita federal: 
                                                <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                    <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                        {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                }
                                {serasaEstimatedIncome.length > 0 &&
                                    <div className="normalText" style={{borderRadius: "8px", marginTop: "16px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                        Renda presumida: 
                                        <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                            {formatAsMoney(serasaEstimatedIncome[0])}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{width: '5%'}}/>
                            <div style={{width: '35%', maxWidth: "296px"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8, marginTop: 0}} className={["blueText", "textSize"].join(" ")}>{t('client_selfie')}</div>
                                <ClientSelfieComponent face_match={decision_metadata.face_match} fraud_face={decision_metadata.fraud_face} validator_face_match_on_database={decision_metadata.validator_face_match_on_database} registration_key={props.natural_person.face.registration_key} />
                            </div>
                        </div>
    
                    : 
                    <div style={{marginBottom: "16px"}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: "100%", marginTop: 24}} className={["blueText", "textSize"].join(" ")}>
                            {t('Dados obtidos de fontes externas')}
                        </div>
                        {renderDataFields()}
                        {receitaFederalData &&
                            <div>
                                {receitaFederalData.flag === 'negative' &&
                                    <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                        Situação na Receita federal: 
                                        <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                            <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                                {receitaFederalData.flag === 'positive' &&
                                    <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                        Situação na Receita federal: 
                                        <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                            <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                            </div>
                        }
                        {serasaEstimatedIncome.length > 0 &&
                            <div className="normalText" style={{borderRadius: "8px", marginTop: "16px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                Renda presumida: 
                                <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                    {formatAsMoney(serasaEstimatedIncome[0])}
                                </div>
                            </div>
                        }
                    </div>
                    }
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 16, alignItems: 'center'}} className={["blueText", "textSize"].join(" ")}>
                        {t('Validação dos dados enviados')}
                        <Tooltip title={t('validation_label')} placement="right">
                            <HelpIcon style={{width: "16px", height: "16px", marginLeft: "4px"}}/>
                        </Tooltip>
                    </div>
                    {renderComparativeTable(props.natural_person, registration_data)}
                </div>
            </div>
        );
    } else {
        return (
            <div className="analysisCard" style={{ height: "100%" }}>
                    <div style={{marginBottom: 12}} className={["blueText", "subtitleSize"].join(" ")}>{t("onboarding_data")} - CPF: {props.natural_person.document_number}</div>
                    {checkNested(props.natural_person, "face", "registration_key") ?
                        <div style={{display: 'flex', flexDirection: 'row', width: "100%", marginTop: 24}}>
                            <div style={{flex: 1}}>
                                <div style={{marginBottom: "8px"}} className={["blueText", "textSize"].join(" ")}>{t('Dados enviados')}</div>
                                {renderComparativeTable(props.natural_person, null)}
                            </div>
                            <div style={{width: '5%'}}/>
                            <div style={{width: '35%', maxWidth: "296px"}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8, marginTop: 0}} className={["blueText", "textSize"].join(" ")}>{t('client_selfie')}</div>
                                <ClientSelfieComponent face_match={decision_metadata.face_match} fraud_face={decision_metadata.fraud_face} registration_key={props.natural_person.face.registration_key} />
                            </div>
                                {receitaFederalData &&
                                    <div>
                                        {receitaFederalData.flag === 'negative' &&
                                            <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                                Situação na Receita federal: 
                                                <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                    <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                        {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        }
                                        {receitaFederalData.flag === 'positive' &&
                                            <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                                Situação na Receita federal: 
                                                <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                    <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                        {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                }
                                {serasaEstimatedIncome.length > 0 &&
                                    <div className="normalText" style={{borderRadius: "8px", marginTop: "16px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                        Renda presumida: 
                                        <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                            {formatAsMoney(serasaEstimatedIncome[0])}
                                        </div>
                                    </div>
                                }
                        </div>
    
                        : 
                        <>
                            {receitaFederalData &&
                                <div>
                                    {receitaFederalData.flag === 'negative' &&
                                        <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                            Situação na Receita federal: 
                                            <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                    {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                    {receitaFederalData.flag === 'positive' &&
                                        <div className="normalText" style={{borderRadius: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                            Situação na Receita federal: 
                                            <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                                <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                                    {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                            }
                            {serasaEstimatedIncome.length > 0 &&
                                <div className="normalText" style={{borderRadius: "8px", marginTop: "16px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                                    Renda presumida: 
                                    <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                        {formatAsMoney(serasaEstimatedIncome[0])}
                                    </div>
                                </div>
                            }
                            <div style={{display: 'flex', flexDirection: 'row', width: "100%", marginTop: 24, marginBottom: "8px"}} className={["blueText", "textSize"].join(" ")}>
                                {t('Dados enviados')}
                            </div>
                            {renderComparativeTable(props.natural_person, null)}
                        </>
                    }
            </div>
        );
    }
}

export default ClientProfile;



function ClientSelfieComponent(props) {
    const fraud_face = props?.fraud_face;
    const face_match = props?.face_match;
    const fraud_face_match = props?.fraud_face_match;
    const registration_key = props?.registration_key;
    const validator_face_match_on_database = props?.validator_face_match_on_database;

    const translateRecomendation = (enumerator) => {
        switch (enumerator) {
            case 'manual_analysis':
                return ' (inconclusivo)'
            case 'new_capture':
                return ' (inconclusivo)'
            case 'reprove':
                return ' (recomenda-se reprovar)'
            case 'proceed_with_caution':
                return ' (recomenda-se prosseguir com cautela)'
            case 'approve':
                return ' (recomenda-se aprovar)'
            default:
                return ''
        }
    }

    if (!fraud_face && !face_match && !fraud_face_match && !validator_face_match_on_database) {
        return (
            <ValidatedImage
                image_key={registration_key}
                dash_api_endpoint_url="/dash/face_recognition/image"
                imageStyle={{margin: "auto",maxWidth: "100%",maxHeight: "100%",borderRadius: "5px",objectFit: "contain"}}
                imageAlt={"client selfie"}
            />
        )
    }

    return (
        <>
            <ValidatedImage
                image_key={registration_key}
                dash_api_endpoint_url="/dash/face_recognition/image"
                imageStyle={{margin: "auto",maxWidth: "100%",maxHeight: "100%",borderRadius: "5px",objectFit: "contain"}}
                imageAlt={"client selfie"}
            />
            <div style={{display: 'flex', flexDirection: 'column', gap: '4px', marginTop: '16px'}}>
                {fraud_face?.flag ?
                    <Tooltip placement="right" title={fraud_face.description}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '4px', width: 'fit-content'}}>{getIndicatorsFlagIcon(fraud_face.flag, 0)} <div style={{color: '#0b1f82'}}>Prova de vida</div></div>
                    </Tooltip>
                    : <></>
                }
                {face_match?.flag ?
                    <Tooltip placement="right" title={face_match.description}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '4px', width: 'fit-content'}}>{getIndicatorsFlagIcon(face_match.flag, 0)} <div style={{color: '#0b1f82'}}>Validação de rosto com foto do documento</div></div>
                    </Tooltip>
                    : <></>
                }
                {fraud_face_match?.flag ?
                    <Tooltip placement="right" title={fraud_face_match.description}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '4px', width: 'fit-content'}}>{getIndicatorsFlagIcon(fraud_face_match.flag, 0)} <div style={{color: '#0b1f82'}}>Validação em base de fraudadores</div></div>
                    </Tooltip>
                    : <></>
                }
                {validator_face_match_on_database?.recommendation ?
                    <Tooltip placement="right" title={validator_face_match_on_database.face_match_available ? ('Face do CPF fornecido encontrada na base de rostos. Similaridade com a foto capturada: ' + (validator_face_match_on_database.face_match_score || 0).toFixed(2).replace('.', ',') + '%') : ('Face do CPF fornecido não encontrada na base de rostos')}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '4px', width: 'fit-content'}}>
                            {getIndicatorsFlagIcon(validator_face_match_on_database.face_match_available ? validator_face_match_on_database.recommendation === 'approve' ? 'positive' : 'negative' : 'neutral', 0)}
                            <div style={{color: '#0b1f82'}}>Validação em base de rostos {translateRecomendation(validator_face_match_on_database?.recommendation)}</div>
                        </div>
                    </Tooltip>
                    : <></>
                }
            </div>
        </>
    );
}
import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
`;
export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const cardContainer = styled.div `
    width: 100%;
    display: block;
`;

export const textHeader = styled.div `
    display: "flex"
`;

export const topBar = styled.div `
    width: "auto"; 
    padding-bottom: "1000px";
`;





import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import {
  getAccountMonitoringTopicIcon,
  getAccountMonitoringTopicEnum,
  getAccountMonitoringTopicStatusEnum,
} from "../../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
  tableStyle: {
    border: "none",
    boxShadow: "none",
  },
  blueText: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#0b1f82",
  },
  tableText: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    margin: "10px",
    padding: "12px",
    border: "none"
  },
  tableHeader: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#0b1f82",
    fontSize: "20px",
    lineHeight: "30px",
    padding: "14px",
  },
}));

function AccountResumeTable(props) {
  const classes = useStyles();
  useTheme();

  const { account_resume } = props


  return (
    <Paper className={classes.tableStyle}>
      <Table style={{ padding: "8px" }}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Tópicos Monitorados</TableCell>
            <TableCell className={classes.tableHeader}>Status</TableCell>
            <TableCell className={classes.tableHeader}>Última Atualização</TableCell>
            <TableCell className={classes.tableHeader}>Próxima Atualização</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(account_resume).map(([monitoring_topic, monitoring_topic_resume], index) => (
            <TableRow key={index} className={"odd"}>
              <TableCell className={classes.tableText}>
                <> {getAccountMonitoringTopicEnum(monitoring_topic)}</>
              </TableCell>
              <TableCell className={classes.tableText}>
                <Tooltip
                  title={
                    <p className="tooltipText">
                      {getAccountMonitoringTopicStatusEnum(monitoring_topic, monitoring_topic_resume.account_holders_on)}
                    </p>
                  }
                >
                  <div style={{ display: "flex", margin: "auto 0px" }}>
                  {getAccountMonitoringTopicIcon(monitoring_topic_resume.account_holders_on)}
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(monitoring_topic_resume.last_update).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </TableCell>
              <TableCell className={classes.tableText}>
                {moment(monitoring_topic_resume.next_update).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
export default AccountResumeTable;

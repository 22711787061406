import React from 'react'
import { CircularProgress } from '@material-ui/core';
import AccountResumeTable from './AccountResumeTable/AccountResumeTable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

function AccountResume(props) {

    let {Account} = props
    
    if (Account === null) {
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Resumo da Conta</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (Account === undefined){
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Resumo da Conta</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <FormatListBulletedIcon className={"blueText"}/>
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Resumo da Conta</span>
            </div>
            <div className="internalCardContainer">
                <div>
                    <div>
                        <AccountResumeTable account={Account}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountResume
import React, { useContext, useState, useEffect, useCallback, useRef } from "react"
import axios from 'axios'
import CssBaseline from "@material-ui/core/CssBaseline"
import SideBar from "../ZaigDrawer/SideBar"
import UserManagement from "../UserManagement/UserManagement"
import NaturalPersonsInbox from "../Onboarding/NaturalPersonsInbox/NaturalPersonsInbox"
import NaturalPerson from "../Onboarding/NaturalPerson/NaturalPerson"
import LegalPersonsInbox from "../Onboarding/LegalPersonsInbox/LegalPersonsInbox"
import LegalPerson from "../Onboarding/LegalPerson/LegalPerson"
import TransactionsInbox from "../CardIssuance/TransactionsInbox/TransactionsInbox"
import Transaction from "../CardIssuance/Transaction/Transaction"
import CardIssuanceAlertsInbox from "../CardIssuance/AlertsInbox/AlertsInbox"
import CardIssuanceAlert from "../CardIssuance/Alert/Alert"
import CardIssuanceLocksInbox from "../CardIssuance/LocksInbox/LocksInbox"
import InfractionReportsInbox from "../PIX/InfractionReportsInbox/InfractionReportsInbox"
import RentalAgreement from "../CarRental/RentalAgreement/RentalAgreement"
import DefaultAlertsInbox from "../Alerts/AlertsInbox/AlertsInbox"
import BankingAlert from "../Alerts/Alert/DefaultAlert/BankingAlert"
import DefaultLocksInbox from "../Locks/LocksInbox/LocksInbox"
import BankslipInbox from "../Bankslip/BankslipsInbox/BankslipsInbox"
import Bankslip from "../Bankslip/Bankslip/Bankslip"
import BillPaymentInbox from "../BillPayment/BillPaymentsInbox/BillPaymentsInbox"
import BillPayment from "../BillPayment/BillPayment/BillPayment"
import WireTransferInbox from "../WireTransfer/WireTransfersInbox/WireTransfersInbox"
import WireTransfer from "../WireTransfer/WireTransfer/WireTransfer"
import OperationsInbox from "../Operations/OperationsInbox/OperationsInbox"
import Operation from "../Operations/Operation/Operation"
import WithdrawalInbox from "../Withdrawal/WithdrawalsInbox/WithdrawalsInbox"
import Withdrawal from "../Withdrawal/Withdrawal/Withdrawal"
import DepositInbox from "../Deposit/DepositsInbox/DepositsInbox"
import Deposit from "../Deposit/Deposit/Deposit"
import PixTransactionsInbox from "../PIX/TransactionsInbox/TransactionsInbox"
import PixTransaction from "../PIX/Transaction/Transaction"
import PixDictsInbox from "../PIX/DictsInbox/DictsInbox"
import PixDict from "../PIX/Dict/Dict"
import OrdersInbox from "../CardOrder/OrdersInbox/OrdersInbox"
import Order from "../CardOrder/Order/Order"
import ActiveCardInbox from "../Billing/ActiveCardInbox/ActiveCardInbox"
import CardOrderAlertsInbox from "../CardOrder/AlertsInbox/AlertsInbox"
import CardOrderAlert from "../CardOrder/Alert/Alert"
import CardOrderLocksInbox from "../CardOrder/LocksInbox/LocksInbox"
import Dashboard from "../Dashboard/Dashboard"
import RuleTree from "../RulesEngine/RuleTree/RuleTree"
import RulesInbox from "../RulesEngine/RulesInbox/RulesInbox"
import ManageIntegrationInbox from "../ManageIntegration/ManageIntegrationInbox/ManageIntegrationInbox"
import ListsInbox from "../Lists/ListsInbox/ListsInbox"
import List from "../Lists/List/List"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import AuthContext from "../../context/auth-context"
import CompaniesContext from "../../context/companies-context"
import ReservationsInbox from "../CarRental/ReservationsInbox/ReservationsInbox"
import Reservation from "../CarRental/Reservation/Reservation"
import SearchInbox from "../Search/SearchInbox/SearchInbox"
import CarRentalInbox from "../CarRental/CarRentalInbox/CarRentalInbox"
import ReportsInbox from "../Reports/ReportsInbox"
import CreditAnalysisNaturalPersonsInbox from "../CreditAnalysis/CreditAnalysisNaturalPersonsInbox/CreditAnalysisNaturalPersonsInbox"
import CreditAnalysisNaturalPerson from "../CreditAnalysis/CreditAnalysisNaturalPerson/CreditAnalysisNaturalPerson"
import CreditAnalysisLegalPersonsInbox from "../CreditAnalysis/CreditAnalysisLegalPersonsInbox/CreditAnalysisLegalPersonsInbox"
import CreditAnalysisLegalPerson from "../CreditAnalysis/CreditAnalysisLegalPerson/CreditAnalysisLegallPerson"
import CurrencyExchangeAlert from "../Alerts/Alert/DefaultAlert/CurrencyExchangeAlert"
import { useTranslation } from "react-i18next"
import RentalAgreements from "../CarRental/RentalAgreementsInbox/RentalAgreements"
import TopSecondaryBar from "../ZaigDrawer/TopSecondaryBar"
import NaturalPersonAccountsMonitoringInbox from "../AccountMonitoring/NaturalPersonAccountMonitoringInbox/NaturalPersonAccountsMonitoringInbox"
import NaturalPersonAccount from "../AccountMonitoring/NaturalPersonAccount/NaturalPersonAccount"
import LegalPersonAccountsMonitoringInbox from "../AccountMonitoring/LegalPersonAccountMonitoringInbox/LegalPersonAccountsMonitoringInbox"
import LegalPersonAccount from "../AccountMonitoring/LegalPersonAccount/LegalPersonAccount"

function MainRouter(props) {
    const { language, setLanguage } = props
    const { i18n } = useTranslation()

    let user_data = useContext(AuthContext).user_data
    let user_language = user_data.locale

    useEffect(() => {
        if (user_language) {
            i18n.changeLanguage(user_language)
        }
    }, [i18n, user_language])

    const mainRedirect = (roles) => {
        if (roles.includes("read_onboarding_natural_persons")) {
            return "/natural-persons-inbox"
        }
        else if (roles.includes("read_onboarding_legal_persons")) {
            return "/legal-persons-inbox"
        }
        else if (roles.includes("read_credit_analysis_natural_persons")) {
            return "/credit-analysis-natural-persons-inbox"
        }
        else if (roles.includes("read_credit_analysis_legal_persons")) {
            return "/credit-analysis-legal-persons-inbox"
        }
        else if (roles.includes("card_issuance_transaction_viewer")) {
            return "/transactions-inbox"
        }
        else if (roles.includes("card_issuance_alert_viewer")) {
            return "/card-issuance-alerts-inbox"
        }
        else if (roles.includes("card_issuance_lock_viewer")) {
            return "/card-issuance-locks-inbox"
        }
        else if (["rental_agreements_viewer","rental_agreements_viewer_last_24hrs","read_car_rental_agreements_filtered_sac"].some(role => roles.includes(role))) {
            return "/rental-agreements-inbox"
	    }
        else if (roles.includes("read_car_rental_reservations")) {
            return "/reservations-inbox"
        }
        else if ([
            "read_banking_alerts",
            "read_currency_exchange_alerts"
        ].some(role => roles.includes(role))) {
            return "/default-alerts-inbox"
        }
        else if ([
            "read_bankslip_locks",
            "read_bill_payment_locks",
            "read_wire_transfer_locks",
            "read_pix_transaction_locks",
            "read_pix_dict_operation_locks",
            "read_withdrawal_locks"
        ].some(role => roles.includes(role))) {
            return "/default-locks-inbox"
        }
        else if (roles.includes("read_bankslips")) {
            return "/bankslips-inbox"
        }
        else if (roles.includes("read_wire_transfers")) {
            return "/wire-transfers-inbox"
        }
        else if (roles.includes("read_bill_payments")) {
            return "/bill-payments-inbox"
        }
        else if (roles.includes("read_withdrawals")) {
            return "/withdrawals-inbox"
        }
        else if (roles.includes("read_pix_transactions")) {
            return "/pix-transactions-inbox"
        }
        else if (roles.includes("read_pix_transactions")) {
            return "/pix-infraction-reports-inbox"
        }
        else if (roles.includes("read_pix_dict_operations")) {
            return "/pix-dicts-inbox"
        }
        else if (roles.includes("read_card_order_orders")) {
            return "/orders-inbox"
        }
        else if (roles.includes("read_card_order_alerts")) {
            return "/card-order-alerts-inbox"
        }
        else if (roles.includes("read_card_order_locks")) {
            return "/card-order-locks-inbox"
        }
        else if (roles.includes("operations_viewer")) {
            return "/operations-inbox"
        }
        else if ([
            "read_reports", "read_reports_onboarding_natural_person", "read_reports_onboarding_legal_person"
        ].some(role => roles.includes(role))) {
            return "/reports-inbox"
        }
        else if ([
            "read_pix_dict_operation_dashboard", "read_pix_transaction_dashboard", "read_onboarding_dashboard", "read_onboarding_filtered_company_dashboard",
            "rental_agreements_dashboard_viewer", "read_bankslip_dashboard", "read_bill_payment_dashboard", "read_wire_transfer_dashboard", "read_alerts_dashboard", "read_credit_analysis_dashboard", 
            "read_credit_analysis_filtered_company_dashboard"
        ].some(role => roles.includes(role))) {
            return "/dashboard"
        }
        else if (roles.includes("manage_integration")) {
            return "/manage-integration-inbox"
        }
        else if (roles.includes("read_account_monitoring")) {
            return "/legal-person-accounts-monitoring-inbox"
        }
        else if (roles.includes("read_account_monitoring")) {
            return "/natural-person-accounts-monitoring-inbox"
        }
        else {
            return ""
        }
    }

    const [onboardingCompanyList, setOnboardingCompanyList] = useState(null)

    const [listsCompanyList, setListsCompanyList] = useState(null)

    const [cardIssuanceBackofficeCompanyList, setCardIssuanceBackofficeCompanyList] = useState(null)

    const [pixCompanyList, setPixCompanyList] = useState(null)

    const [creditAnalysisCompanyList, setCreditAnalysisCompanyList] = useState(null)

    const [listsFeatureToggles, setlistsFeatureToggles] = useState(null)

    const [companiesSettings, setCompaniesSettings] = useState(null)

    const [dashboardSettings, setDashboardSettings] = useState(null)

    const [billPaymentCompaniesList, setBillPaymentCompaniesList] = useState(null)
    const [bankslipCompaniesList, setBankslipCompaniesList] = useState(null)
    const [wireTransferCompaniesList, setWireTransferCompaniesList] = useState(null)
    const [withdrawalCompaniesList, setWithdrawalCompaniesList] = useState(null)
    const [depositCompaniesList, setDepositCompaniesList] = useState(null)

    const doRequest = useCallback(
        (service) => {
            if (service === "onboarding" || service === "lists_api" || service === "card_issuance_backoffice" || service === "pix" || service === "credit_analysis") { 
                axios.get('/dash/business_group/' + service + '/companies').then(response => {
                    if (service === "onboarding") setOnboardingCompanyList(response.data)
                    if (service === "lists_api") setListsCompanyList(response.data)
                    if (service === "card_issuance_backoffice") setCardIssuanceBackofficeCompanyList(response.data)
                    if (service === "pix") setPixCompanyList(response.data)
                    if (service === "credit_analysis") setCreditAnalysisCompanyList(response.data)
                }).catch(error => { console.log((error.response || {}).status) })
            if (service === "bill_payment" || service === "bankslip" || service === "wire_transfer" || service === "withdrawal") { 
                axios.get('/dash/business_group/companies/companies').then(response => {
                    if (service === "bill_payment") setBillPaymentCompaniesList(response.data)
                    if (service === "bankslip") setBankslipCompaniesList(response.data)
                    if (service === "wire_transfer") setWireTransferCompaniesList(response.data)
                    if (service === "pix") setPixCompanyList(response.data)
                    if (service === "withdrawal") setWithdrawalCompaniesList(response.data)
                    if (service === "deposit") setDepositCompaniesList(response.data)
                }).catch(error => { console.log((error.response || {}).status) })
            }
            }
        }, [])

    const doListsFeatureTogglesRequest = useCallback(
        (service) => {
            if (service === "lists_api") {
                axios.get('/dash/company/' + service).then(response => {
                    setlistsFeatureToggles(response.data)
                }).catch(error => { console.log((error.response || {}).status) })
            }
        }, [])

    const doRequestCompaniesSettings = useCallback(
        () => {
            axios.get('/dash/companies/settings').then(response => {
                setCompaniesSettings(response.data)
            }).catch(error => { console.log((error.response || {}).status) })
        }, [])
    
    const doRequestDashboardSettings = useCallback(
        () => {
            axios.get('/dash/companies/dashboard_settings').then(response => {
                setDashboardSettings(response.data.dashboard_settings)
            }).catch(error => { console.log((error.response || {}).status) })
        }, [])


    useEffect(() => {
        if (!onboardingCompanyList && user_data.business_group_key) {
            doRequest("onboarding")
            const timer_onb = setInterval(() => {
                doRequest("onboarding")
            }, 15000)
            return (() => {
                clearInterval(timer_onb)
            })
        }
    }, [doRequest, onboardingCompanyList, user_data])

    useEffect(() => {
        if (!cardIssuanceBackofficeCompanyList && user_data.business_group_key && user_data.roles.includes("card_issuance_lock_viewer")) {
            doRequest("card_issuance_backoffice")
            const timer_onb = setInterval(() => {
                doRequest("card_issuance_backoffice")
            }, 15000)
            return (() => {
                clearInterval(timer_onb)
            })
        }
    }, [doRequest, cardIssuanceBackofficeCompanyList, user_data])

    useEffect(() => {
        if (!listsCompanyList && user_data.business_group_key && user_data.roles.includes("lists_viewer")) {
            doRequest("lists_api")
            const timer_onb = setInterval(() => {
                doRequest("lists_api")
            }, 15000)
            return (() => {
                clearInterval(timer_onb)
            })
        }
    }, [doRequest, listsCompanyList, user_data])

    useEffect(() => {
        if (!pixCompanyList && user_data.business_group_key && 
            ["read_pix_transactions", "read_pix_dict_operations"].some(role => user_data.roles.includes(role))) {
            doRequest("pix")
        }
    }, [doRequest, pixCompanyList, user_data])

    useEffect(() => {
        if (!creditAnalysisCompanyList && user_data.business_group_key && 
           ["read_credit_analysis_natural_persons", "read_credit_analysis_legal_persons"].some(role => user_data.roles.includes(role))) {
            doRequest("credit_analysis")
            const timer_credit_analysis = setInterval(() => {
                doRequest("credit_analysis")
            }, 15000)
            return (() => {
                clearInterval(timer_credit_analysis)
            })
        }
    }, [doRequest, creditAnalysisCompanyList, user_data])

    useEffect(() => {
        if (!listsFeatureToggles && user_data.company_key && user_data.roles.includes("lists_viewer")) {
            doListsFeatureTogglesRequest("lists_api")
            const timer_lists = setInterval(() => {
                doListsFeatureTogglesRequest("lists_api")
            }, 15000)
            return (() => {
                clearInterval(timer_lists)
            })
        }
    }, [doListsFeatureTogglesRequest, listsFeatureToggles, user_data])

    useEffect(() => {
        doRequestCompaniesSettings()
        doRequestDashboardSettings()
    }, [doRequestCompaniesSettings, doRequestDashboardSettings])
    
    useEffect(() => {
        if (!billPaymentCompaniesList && user_data.business_group_key &&  user_data.roles.includes(["read_bill_payments", "read_banking_alerts"])){
            doRequest("bill_payment")
            const timer_bill_payment = setInterval(() => {
                doRequest("bill_payment")
            }, 15000)
            return (() => {
                clearInterval(timer_bill_payment)
            })
        }
    }, [doRequest, billPaymentCompaniesList, user_data])

    useEffect(() => {
        if (!bankslipCompaniesList && user_data.business_group_key &&  user_data.roles.includes(["read_bankslips", "read_banking_alerts"])){
            doRequest("bankslip")
            const timer_bankslip = setInterval(() => {
                doRequest("bankslip")
            }, 15000)
            return (() => {
                clearInterval(timer_bankslip)
            })
        }
    }, [doRequest, bankslipCompaniesList, user_data])

    useEffect(() => {
        if (!wireTransferCompaniesList && user_data.business_group_key &&  user_data.roles.includes(["read_wire_transfers", "read_banking_alerts"])){
            doRequest("wire_transfer")
            const timer_wire_transfer = setInterval(() => {
                doRequest("wire_transfer")
            }, 15000)
            return (() => {
                clearInterval(timer_wire_transfer)
            })
        }
    }, [doRequest, wireTransferCompaniesList, user_data])

    useEffect(() => {
        if (!withdrawalCompaniesList && user_data.business_group_key &&  user_data.roles.includes(["read_withdrawals", "read_banking_alerts"])){
            doRequest("withdrawal")
            const timer_withdrawal = setInterval(() => {
                doRequest("withdrawal")
            }, 15000)
            return (() => {
                clearInterval(timer_withdrawal)
            })
        }
    }, [doRequest, withdrawalCompaniesList, user_data])

    useEffect(() => {
        if (!depositCompaniesList && user_data.business_group_key &&  user_data.roles.includes(["read_deposits", "read_banking_alerts"])){
            doRequest("deposit")
            const timer_deposit = setInterval(() => {
                doRequest("deposit")
            }, 15000)
            return (() => {
                clearInterval(timer_deposit)
            })
        }
    }, [doRequest, depositCompaniesList, user_data])

    const rolesToProductPages = [
        {
            roles: ['read_onboarding_natural_persons'],
            product: 'onboarding',
            page: 'onboarding_natural_persons'
        },
        {
            roles: ['read_onboarding_legal_persons'],
            product: 'onboarding',
            page: 'onboarding_legal_persons'
        },
        {
            roles: ["read_credit_analysis_natural_persons"],
            product: 'credit_analysis',
            page: 'credit_analysis_natural_persons'
        },
        {
            roles: ['read_credit_analysis_legal_persons'],
            product: 'credit_analysis',
            page: 'credit_analysis_legal_persons'
        },
        {
            roles: ['card_issuance_transaction_viewer'],
            product: 'card_issuance',
            page: 'card_issuance_transactions'
        },
        {
            roles: ['card_issuance_alert_viewer'],
            product: 'card_issuance',
            page: 'card_issuance_alert'
        },
        {
            roles: ['card_issuance_lock_viewer'],
            product: 'card_issuance',
            page: 'card_issuance_lock'
        },
        {
            roles: ['read_card_order_orders'],
            product: 'card_order',
            page: 'card_order_orders'
        },
        {
            roles: ['read_card_order_alerts'],
            product: 'card_order',
            page: 'card_order_alerts'
        },
        {
            roles: ['read_card_order_locks'],
            product: 'card_order',
            page: 'card_order_locks'
        },
        {
            roles: ['rental_agreements_viewer','read_car_rental_agreements_filtered_sac'],
            product: 'car_rental',
            page: 'car_rental_rental_agreements'
        },
        {
            roles: ["read_car_rental_reservations", "rental_agreements_viewer"],
            product: 'car_rental',
            page: 'car_rental_rental_agreements_reservations'
        },
        {
            roles: ['read_car_rental_reservations'],
            product: 'car_rental',
            page: 'car_rental_reservations'
        },
        {
            roles: ['read_bankslips'],
            product: 'bankslip',
            page: 'bankslip'
        },
        {
            roles: ['read_bill_payments'],
            product: 'bill_payment',
            page: 'bill_payment'
        },
        {
            roles: ['read_wire_transfers'],
            product: 'wire_transfer',
            page: 'wire_transfer'
        },
        {
            roles: ['read_withdrawals'],
            product: 'withdrawal',
            page: 'withdrawal'
        },
        {
            roles: ['read_deposits'],
            product: 'deposit',
            page: 'deposit'
        },
        {
            roles: ['read_pix_transactions'],
            product: 'pix',
            page: 'pix_transactions'
        },
        {
            roles: ['read_pix_transactions'],
            product: 'pix',
            page: 'pix_infraction_reports'
        },
        {
            roles: ['read_pix_dict_operations'],
            product: 'pix',
            page: 'pix_dict_operations'
        },
        {
            roles: ['billing_viewer'],
            product: 'payments',
            page: 'payments'
        },
        {
            roles: ['read_banking_alerts','read_currency_exchange_alerts'],
            product: 'alerts',
            page: 'alerts'
        },
        {
            roles: ['operations_viewer'],
            product: 'operations',
            page: 'operations'
        },
        {
            roles: [
                'read_bankslip_locks',
                'read_bill_payment_locks',
                'read_wire_transfer_locks',
                'read_pix_transaction_locks',
                'read_pix_dict_operation_locks',
                'read_withdrawal_locks'
            ],
            product: 'locks',
            page: 'locks'
        },
        {
            roles: ['read_reports'],
            product: 'reports',
            page: 'reports'
        },
        {
            roles: ['lists_viewer'],
            product: 'lists',
            page: 'lists'
        },
        {
            roles: ['rule_viewer'],
            product: 'rules',
            page: 'rules'
        },
        {
            roles: [
                'card_issuance_transaction_dashboard_filtered_company_viewer',
                'read_onboarding_dashboard',
                'read_onboarding_filtered_company_dashboard',
                'read_bankslip_dashboard',
                'read_bill_payment_dashboard',
                'read_pix_dict_operation_dashboard',
                'read_pix_transaction_dashboard',
                'read_wire_transfer_dashboard',
                'rental_agreements_dashboard_viewer',
                'read_credit_analysis_filtered_company_dashboard'
            ],
            product: 'dashboard',
            page: 'dashboard'
        },
        {
            roles: [
                'rental_agreements_manager',
                'manage_bankslips_permissions',
                'manage_banking_alerts_permissions',
                'manage_bankslip_locks_permissions',
                'manage_bill_payment_locks_permissions',
                'manage_bill_payments_permissions',
                'card_issuance_lock_manager',
                'card_issuance_transaction_manager',
                'card_issuance_alert_manager',
                'manage_onboarding_natural_persons_permissions',
                'manage_onboarding_legal_persons_permissions',
                'manage_card_order_orders_permissions',
                'manage_card_order_alerts_permissions',
                'manage_card_order_locks_permissions',
                'operations_manager',
                'manage_pix_transactions_permissions',
                'manage_pix_transaction_locks_permissions',
                'manage_pix_dict_operations_permissions',
                'manage_pix_dict_operation_locks_permissions',
                'rule_manager',
                'lists_manager',
                'manage_wire_transfers_permissions',
                'manage_wire_transfer_locks_permissions',
                'manage_withdrawals_permissions',
                'manage_withdrawal_locks_permissions',
                'manage_credit_analysis_natural_persons_permissions',
                'manage_credit_analysis_legal_persons_permissions'
            ],
            product: 'user_management',
            page: 'user_management'
        },
        {
            roles: [
                'manage_integration'
            ],
            product: 'manage_integration',
            page: 'manage_integration'
        },
        {
            roles : ['read_account_monitoring'],
            product : 'account_monitoring',
            page : 'natural_person_accounts_monitoring'
        },
        {
            roles : ['read_account_monitoring'], 
            product : 'account_monitoring',
            page : 'legal_person_accounts_monitoring'
        }
    ]

    const uniqueArray = (array) => {
        return [...new Set(array)];
    }

    const getAllowedProductPages = () => {
        let allowedProducts = []
        let allowedPages = []
        rolesToProductPages.forEach((obj)=>{
            let { roles, product, page } = obj
            if (roles.some((role) => user_data.roles.includes(role))) {
                allowedProducts.push(product)
                allowedPages.push(page)
            }
        })
        return {
            allowedProducts: uniqueArray(allowedProducts),
            allowedPages: uniqueArray(allowedPages)
        }
    }

    const { allowedProducts, allowedPages } = getAllowedProductPages()

    const pages = {
        onboarding: ["onboarding_natural_persons","onboarding_legal_persons"],
        credit_analysis: ["credit_analysis_natural_persons","credit_analysis_legal_persons"],
        card_issuance: ['card_issuance_transactions','card_issuance_alert','card_issuance_lock'],
        card_order: ['card_order_orders','card_order_alerts','card_order_locks'],
        car_rental: ['car_rental_rental_agreements','car_rental_reservations','car_rental_rental_agreements_reservations'],
        bankslip: ['bankslip'],
        bill_payment: ['bill_payment'],
        withdrawal: ['withdrawal'],
        deposit: ['deposit'],
        alerts: ['alerts'],
        locks: ['locks'],
        wire_transfer: ['wire_transfer'],
        pix: ['pix_transactions','pix_dict_operations','pix_infraction_reports'],
        operations: ['operations'],
        dashboard: ['dashboard'],
        rules: ['rules'],
        lists: ['lists'],
        reports: ['reports'],
        payments: ['payments'],
        user_management: ['user_management'],
        search_banking: ['search_banking'],
        manage_integration: ['manage_integration'],
        account_monitoring: ['natural_person_accounts_monitoring', 'legal_person_accounts_monitoring']
    }

    const pagesToRoutes = {
        onboarding_natural_persons: "/natural-persons-inbox",
        onboarding_legal_persons: "/legal-persons-inbox",
        credit_analysis_natural_persons: "/credit-analysis-natural-persons-inbox",
        credit_analysis_legal_persons : "/credit-analysis-legal-persons-inbox",
        user_management: "/user-management",
        card_issuance_transactions: "/transactions-inbox",
        card_issuance_alert: "/card-issuance-alerts-inbox",
        card_issuance_lock: "/card-issuance-locks-inbox",
        car_rental_rental_agreements: "/rental-agreements-inbox",
        car_rental_reservations: "/reservations-inbox",
        car_rental_rental_agreements_reservations: "/car-rental-inbox",
        locks: "/default-locks-inbox",
        alerts: "/default-alerts-inbox",
        bankslip: "/bankslips-inbox",
        bill_payment: "/bill-payments-inbox",
        wire_transfer: "/wire-transfers-inbox",
        withdrawal: "/withdrawals-inbox",
        deposit: "/deposits-inbox",
        pix_transactions: "/pix-transactions-inbox",
        pix_dict_operations: "/pix-dicts-inbox",
        pix_infraction_reports: "/pix-infraction-reports-inbox",
        operations: "/operations-inbox",
        card_order_orders: "/orders-inbox",
        card_order_alerts: "/card-order-alerts-inbox",
        card_order_locks: "/card-order-locks-inbox",
        reports: "/reports-inbox",
        dashboard: "/dashboard",
        rules: "/rules-inbox",
        lists: "/lists-inbox",
        payments: "/payments",
        search_banking: "/search-banking-inbox",
        manage_integration: "/manage-integration-inbox",
        natural_person_accounts_monitoring: "/natural-person-accounts-monitoring-inbox",
        legal_person_accounts_monitoring: "/legal-person-accounts-monitoring-inbox"
    }

    const routesToPages = {
        "natural-persons-inbox": "onboarding_natural_persons",
        "legal-persons-inbox": "onboarding_legal_persons",
        "credit-analysis-natural-persons-inbox": "credit_analysis_natural_persons",
        "credit-analysis-legal-persons-inbox": "credit_analysis_legal_persons",
        "user-management": "user_management",
        "transactions-inbox": "card_issuance_transactions",
        "card-issuance-alerts-inbox": "card_issuance_alert",
        "card-issuance-locks-inbox": "card_issuance_lock",
        "rental-agreements-inbox": "car_rental_rental_agreements",
        "reservations-inbox": "car_rental_reservations",
        "car-rental-inbox": "car_rental_rental_agreements_reservations",
        "default-locks-inbox": "locks",
        "default-alerts-inbox": "alerts",
        "bankslips-inbox": "bankslip",
        "bill-payments-inbox": "bill_payment",
        "wire-transfers-inbox": "wire_transfer",
        "withdrawals-inbox": "withdrawal",
        "deposits-inbox": "deposit",
        "pix-transactions-inbox": "pix_transactions",
        "pix-dicts-inbox": "pix_dict_operations",
        "pix-infraction-reports-inbox": "pix_infraction_reports",
        "operations-inbox": "operations",
        "orders-inbox": "card_order_orders",
        "card-order-alerts-inbox": "card_order_alerts",
        "card-order-locks-inbox": "card_order_locks",
        "reports-inbox": "reports",
        "dashboard": "dashboard",
        "rules-inbox": "rules",
        "lists-inbox": "lists",
        "payments": "payments",

        "natural-person": "onboarding_natural_persons",
        "legal-person": "onboarding_legal_persons",
        "credit-analysis-natural-person": "credit_analysis_natural_persons",
        "credit-analysis-legal-person": "credit_analysis_legal_persons",
        "transaction": "card_issuance_transactions",
        "alert": "card_issuance_alert",
        "rental-agreement": "car_rental_rental_agreements",
        "reservation": "car_rental_rental_agreements_reservations",
        "banking-alert": "alerts",
        "currency-exchange-alert": "alerts",
        "bankslip": "bankslip",
        "bill-payment": "bill_payment",
        "wire-transfer": "wire_transfer",
        "withdrawal": "withdrawal",
        "deposit": "deposit",
        "pix-transaction": "pix_transactions",
        "operation": "operations",
        "pix-dict": "pix_dict_operations",
        "order": "card_order_orders",
        "card-order-alert": "card_order_alerts",
        "rule": "rules",
        "list": "lists",
        "search-banking-inbox" : "search_banking",
        "manage-integration-inbox": "manage_integration",
        "natural-person-accounts-monitoring-inbox":"natural_person_accounts_monitoring",
        "legal-person-accounts-monitoring-inbox":"legal_person_accounts_monitoring"

    }
    
    const [selectedProduct, setSelectedProduct] = useState(allowedProducts[0])

    const [selectedPage, setSelectedPage] = useState((pages[selectedProduct]||[])[0])
    
    const currentPath = window.location.pathname;

    const slicedPath = (url) => {
        if (url) {
            return url.substring(1).split("/")[0];
        } else {
            return ""
        }
    }

    useEffect(()=>{
        const slicedCurrentPath = slicedPath(currentPath)
        if (slicedCurrentPath!==pagesToRoutes[selectedProduct]) {
            const currentPage = routesToPages[slicedCurrentPath]
            let currentProduct = ""
            Object.keys(pages).forEach((product)=>{
                if (pages[product].includes(currentPage)) {
                    currentProduct = product
                }
            })
            setSelectedPage(currentPage)
            setSelectedProduct(currentProduct);
        }
    },[currentPath,pagesToRoutes,selectedProduct,pages,routesToPages])

    const scriptRef = useRef();

    useEffect(() => {
        if (!window.FreshworksWidget) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://widget.freshworks.com/widgets/67000004119.js';
            script.async = true;
            script.defer = true;
        
            scriptRef.current = script;
        
            document.body.appendChild(script);
            }
        
            return () => {
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current);
            }
            };
    }, []);
  
    useEffect(() => {
        window.fwSettings = {
            widget_id: 67000004119,
            };
    }, []);

    const { keycloak_adapter } = useContext(AuthContext);
    
    useEffect(() => {
        function updateActivityTimestamp() {
            localStorage.setItem("@kyc_session", Date.now());
        }
    
        function checkInactivity() {
            const previousActivityTimestamp = localStorage.getItem("@kyc_session");
            if (previousActivityTimestamp) {
                const inactiveTime = Date.now() - parseInt(previousActivityTimestamp, 10);
                const inactivityThreshold = 30 * 60 * 1000; // 30 minutes
                if (inactiveTime > inactivityThreshold) {
                    localStorage.removeItem("@kyc_session");
                    sessionStorage.clear();
                    keycloak_adapter.logout();
                }
            } else {
                updateActivityTimestamp();
            }
        }
        
        window.addEventListener('click'   , updateActivityTimestamp)
        window.addEventListener('keypress', updateActivityTimestamp)
        const intervalId = setInterval(checkInactivity, 1000);

        return () => {
            window.removeEventListener('click'   , updateActivityTimestamp);
            window.removeEventListener('keypress', updateActivityTimestamp);
            clearInterval(intervalId);
        }

    }, [keycloak_adapter])

    return (
        <div>
            <CompaniesContext.Provider
                value={{
                    onboarding_list: onboardingCompanyList,
                    card_issuance_backoffice_list: cardIssuanceBackofficeCompanyList,
                    lists_companies: listsCompanyList,
                    pix_companies: pixCompanyList,
                    credit_analysis_companies: creditAnalysisCompanyList,
                    lists_feature_toggle: listsFeatureToggles,
                    settings: companiesSettings,
                    dashboard_settings: dashboardSettings,
                    bill_payment_companies: billPaymentCompaniesList,
                    bankslip_companies: bankslipCompaniesList,
                    wire_transfer_companies: wireTransferCompaniesList,
                    withdrawal_companies: withdrawalCompaniesList,
                    deposit_companies: depositCompaniesList
                }}
            >
                <Router>
                    <CssBaseline />
                    <div style={{width: "100%", height: "100%"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <SideBar 
                                language={language}
                                setLanguage={setLanguage}
                                selectedProduct={selectedProduct}
                                setSelectedProduct={setSelectedProduct}
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                                pages={pages}
                                routes={pagesToRoutes}
                                allowedProducts={allowedProducts}
                            />
                            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                                <TopSecondaryBar 
                                    language={language}
                                    selectedProduct={selectedProduct}
                                    pages={pages} 
                                    routes={pagesToRoutes}
                                    selectedPage={selectedPage}
                                    setSelectedPage={setSelectedPage}
                                    allowedPages={allowedPages}
                                />
                                <div className="mainPageBorder" style={{paddingBottom: 100, maxHeight: "calc(600px)", overflowY: "auto"}}>
                                    <main className="mainPage">
                                        <Switch>
                                            <Route path="/user-management">
                                                <UserManagement />
                                            </Route>
                                            <Route path="/natural-persons-inbox">
                                                <NaturalPersonsInbox />
                                            </Route>
                                            <Route path="/natural-person/:natural_person_key">
                                                <NaturalPerson />
                                            </Route>
                                            <Route path="/legal-persons-inbox">
                                                <LegalPersonsInbox />
                                            </Route>
                                            <Route path="/legal-person/:legal_person_key">
                                                <LegalPerson />
                                            </Route>
                                            <Route path="/credit-analysis-natural-persons-inbox">
                                                <CreditAnalysisNaturalPersonsInbox />
                                            </Route>
                                            <Route path="/credit-analysis-natural-person/:credit_proposal_natural_person_key">
                                                <CreditAnalysisNaturalPerson />
                                            </Route>
                                            <Route path="/credit-analysis-legal-persons-inbox">
                                                <CreditAnalysisLegalPersonsInbox />
                                            </Route>
                                            <Route path="/credit-analysis-legal-person/:credit_proposal_legal_person_key">
                                                <CreditAnalysisLegalPerson />
                                            </Route>
                                            <Route path="/transactions-inbox">
                                                <TransactionsInbox />
                                            </Route>
                                            <Route path="/transaction/:transaction_key">
                                                <Transaction />
                                            </Route>
                                            <Route path="/card-issuance-alerts-inbox">
                                                <CardIssuanceAlertsInbox />
                                            </Route>
                                            <Route path="/alert/:alert_key">
                                                <CardIssuanceAlert />
                                            </Route>
                                            <Route path="/card-issuance-locks-inbox">
                                                <CardIssuanceLocksInbox />
                                            </Route>
                                            <Route path="/rental-agreements-inbox">
                                                <RentalAgreements />
                                            </Route>
                                            <Route path="/rental-agreement/:rental_agreement_key">
                                                <RentalAgreement /> 
                                            </Route>
                                            <Route path="/reservations-inbox">
                                                <ReservationsInbox />
                                            </Route>
                                            <Route path="/reservation/:reservation_key">
                                                <Reservation />
                                            </Route>
                                            <Route path="/car-rental-inbox">
                                                <CarRentalInbox />
                                            </Route>
                                            <Route path="/default-locks-inbox">
                                                <DefaultLocksInbox />
                                            </Route>
                                            <Route path="/default-alerts-inbox">
                                                <DefaultAlertsInbox />
                                            </Route>
                                            <Route path="/banking-alert/:alert_key">
                                                <BankingAlert />
                                            </Route>
                                            <Route path="/currency-exchange-alert/:alert_key">
                                                <CurrencyExchangeAlert />
                                            </Route>
                                            <Route path="/bankslips-inbox">
                                                <BankslipInbox />
                                            </Route>
                                            <Route path="/bankslip/:bankslip_key">
                                                <Bankslip />
                                            </Route>
                                            <Route path="/bill-payments-inbox">
                                                <BillPaymentInbox />
                                            </Route>
                                            <Route path="/bill-payment/:bill_payment_key">
                                                <BillPayment />
                                            </Route>
                                            <Route path="/wire-transfers-inbox">
                                                <WireTransferInbox />
                                            </Route>
                                            <Route path="/wire-transfer/:wire_transfer_key">
                                                <WireTransfer />
                                            </Route>
                                            <Route path="/withdrawals-inbox">
                                                <WithdrawalInbox />
                                            </Route>
                                            <Route path="/withdrawal/:withdrawal_key">
                                                <Withdrawal />
                                            </Route>
                                            <Route path="/deposits-inbox">
                                                <DepositInbox />
                                            </Route>
                                            <Route path="/deposit/:deposit_key">
                                                <Deposit />
                                            </Route>
                                            <Route path="/pix-transactions-inbox">
                                                <PixTransactionsInbox />
                                            </Route>
                                            <Route path="/pix-transaction/:transaction_key">
                                                <PixTransaction />
                                            </Route>
                                            <Route path="/operations-inbox">
                                                <OperationsInbox />
                                            </Route>
                                            <Route path="/operation/:operation_key">
                                                <Operation />
                                            </Route>
                                            <Route path="/pix-dicts-inbox">
                                                <PixDictsInbox />
                                            </Route>
                                            <Route path="/pix-dict/:dict_operation_key">
                                                <PixDict />
                                            </Route>
                                            <Route path="/pix-infraction-reports-inbox">
                                                <InfractionReportsInbox />
                                            </Route>
                                            <Route path="/order/:order_key">
                                                <Order />
                                            </Route>
                                            <Route path="/orders-inbox">
                                                <OrdersInbox />
                                            </Route>
                                            <Route path="/card-order-alert/:alert_key">
                                                <CardOrderAlert />
                                            </Route>
                                            <Route path="/card-order-alerts-inbox">
                                                <CardOrderAlertsInbox />
                                            </Route>
                                            <Route path="/card-order-locks-inbox">
                                                <CardOrderLocksInbox />
                                            </Route>
                                            <Route path="/reports-inbox">
                                                <ReportsInbox />
                                            </Route>
                                            <Route path="/dashboard">
                                                <Dashboard />
                                            </Route>
                                            <Route path="/rules-inbox">
                                                <RulesInbox />
                                            </Route>
                                            <Route path="/rule/:rule_key/:version">
                                                <RuleTree />
                                            </Route>
                                            <Route path="/lists-inbox">
                                                <ListsInbox />
                                            </Route>
                                            <Route path="/list/:list_key">
                                                <List />
                                            </Route>
                                            <Route path="/payments">
                                                <ActiveCardInbox />
                                            </Route>
                                            <Route path="/manage-integration-inbox">
                                                <ManageIntegrationInbox />
                                            </Route>
                                            <Route path="/natural-person-accounts-monitoring-inbox">
                                                <NaturalPersonAccountsMonitoringInbox />
                                            </Route>
                                            <Route path="/legal-person-account/:legal_person_account_key">
                                                <LegalPersonAccount />
                                            </Route>
                                            <Route path="/natural-person-account/:natural_person_account_key">
                                                <NaturalPersonAccount />
                                            </Route>
                                            <Route path="/legal-person-accounts-monitoring-inbox">
                                                <LegalPersonAccountsMonitoringInbox />
                                            </Route>
                                            <Route path="/search-banking-inbox/:search_term">
                                                <SearchInbox />
                                            </Route>
                                            <Route exact path="">
                                                <Redirect to={mainRedirect(user_data.roles)} />
                                            </Route>
                                        </Switch>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
            </CompaniesContext.Provider>
            </div>
        )
}


export default MainRouter


import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";

export const StyledTableRow = styled(TableRow)`
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  color: #6f6f6f;
  font-size: 20px;
  line-height: 19px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.37);
  transition: background-color 0.3s ease;
  border-radius: 5px;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;




import React, { useEffect, useContext, useReducer} from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import AuthContext from "../../../context/auth-context"
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from '@material-ui/core'
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { useHistory } from 'react-router-dom'
import AccountPersons from "./AccountPersons/AccountPersons";
import AccountResume from "./AccountResume/AccountResume";

function NaturalPersonAccount(props) {
	let history = useHistory()

	let user_data = useContext(AuthContext).user_data

	if (!user_data.roles.includes("read_account_monitoring")) {
		history.push("")
	}

	let { natural_person_account_key } = useParams();


	const [natural_person_account, dispatchNaturalPersonAccount] = useReducer(
		dataFetchReducer,
		{ fetchedData: null, isLoading: true, isError: false }
	)

	useEffect(() => {
		dispatchNaturalPersonAccount({ type: "data_fetch_init" })
		const timer = setTimeout(() => {
			axios.get('/dash/account_monitoring/natural_person_account/' + natural_person_account_key).then(response => {
				dispatchNaturalPersonAccount({
					type: "data_fetch_success",
					payload: response.data
				})
			}).catch(error => {
				if ((error.response || {}).status === 403) dispatchNaturalPersonAccount({ type: "data_fetch_failure_403" })
				else if ((error.response || {}).status === 404) dispatchNaturalPersonAccount({ type: "data_fetch_failure_404" })
				else dispatchNaturalPersonAccount({ type: "data_fetch_failure" })
			})
		}, 500);
		return () => {
			clearTimeout(timer)
		}
	}, [natural_person_account_key])

	if (natural_person_account.fetchedData) {
		return (
			<div style={{ minWidth: "900px", overflow: "auto", paddingBottom: "30px" }}>
				<div className="analysisCardContainer" style={{ display: "flex" }}>
					<ErrorBoundary>
						<div>
							<div className={["blueText", "titleSize"].join(" ")} style={{ margin: "5px 0px" , marginBottom: "30px" }}>
								{"Conta N° - " + natural_person_account.fetchedData.account_id}
							</div>
						</div>
					</ErrorBoundary>
				</div>
				<div className="analysisCardContainer">
					<ErrorBoundary>
						<AccountResume Account={natural_person_account.fetchedData} />
					</ErrorBoundary>
				</div>

				<div className="analysisCardContainer">
					<ErrorBoundary>
						<AccountPersons AccountPersons={natural_person_account.fetchedData.persons} />
					</ErrorBoundary>
				</div>

			</div>
		);
	}
	else if (natural_person_account.isLoading) {
		return (
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
	}
	else if (natural_person_account.isError) {
		return (
			<div style={{ height: "80vh", display: "flex" }}>
				<p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
					{natural_person_account.errorMessage}
				</p>
			</div>
		)
	}

	return null;
}

export default NaturalPersonAccount;

import styled from "styled-components";

export const Subtitle = styled.div`
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: Open Sans;
        font-weight: 600;
        font-style: normal;
        color:rgb(3, 38, 211);
        font-size: 16px;
        line-height: 20px;
`;
import React, { useContext } from "react";
import FilterBox from "../../../Utils/FilterBox/FilterBox"
import CompaniesContext from "../../../../context/companies-context"

function Filter(props) {
	
    const { setPageParams } = props

    let companies = useContext(CompaniesContext).onboarding_list

    let filterTypes = {
        external_id: {
            type: "string",
            disabled: false,
            validation:false,
            description: "ID da Conta"
        },
        status: {
            type: "enum",
            enumList: [
                ["Ativa", "active"],
                ["Desativada", "deactivated"],
            ],
            disabled: false,
            validation:false,
            description: "Status"
        }
    }

    if (companies){
        let enumList = companies.companies.sort((a, b) => a.name === b.name ? 0 : a.name > b.name ? 1 : -1).map(company =>{
            return [company.name, company.company_key]
        })
        filterTypes['company_key'] = {
            type: "enum",
            enumList: [
                ["Nenhum", ""],
                ...enumList
            ],  
            disabled: false,
            validation:false,
            description: "Cliente"
        }
    }

    return (
        <FilterBox 
            setPageParams={setPageParams}
            filterTypes={filterTypes}
            local_storage_object_preffix={props.local_storage_object_preffix}
        />
    )

}

export default Filter

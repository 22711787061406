import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AuthContext from "../../context/auth-context";
import Profile from "./Profile/Profile";
import logoQI from "../../assets/logoQI.png";
import smallQI from "../../assets/smallQI.png";
import SearchBar from "./SearchBar/SearchBar"
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PermissionIcon from "@material-ui/icons/PermIdentityOutlined";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import PersonIcon from "@material-ui/icons/Person";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from "@material-ui/icons/Payment";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LockIcon from "@material-ui/icons/Lock";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import InsertChartIcon from "@material-ui/icons/InsertChart";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SettingsIcon from '@material-ui/icons/Settings';
import BillingIcon from '@material-ui/icons/AttachMoney';
import grayBankslip from '../../assets/cardEntryIcons/grayBankslip.svg'
import grayBillPayment from '../../assets/cardEntryIcons/grayBillPayment.svg'
import grayWireTransfer from '../../assets/cardEntryIcons/grayWireTransfer.svg'
import grayWithdrawal from '../../assets/cardEntryIcons/grayWithdrawal.svg'
import grayDeposit from '../../assets/cardEntryIcons/grayDeposit.svg'
import blueBankslip from '../../assets/cardEntryIcons/blueBankslip19.svg'
import blueBillPayment from '../../assets/cardEntryIcons/blueBillPayment19.svg'
import blueWireTransfer from '../../assets/cardEntryIcons/blueWireTransfer19.svg'
import blueWithdrawal from '../../assets/cardEntryIcons/blueWithdrawal19.svg'
import blueDeposit from '../../assets/cardEntryIcons/blueDeposit.svg'
import RuleIcon from '@mui/icons-material/Rule';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PixIcon from '@mui/icons-material/Pix';
import KeyIcon from '@mui/icons-material/Key';

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#f1f2f7",
    borderRight: "1px solid #f5f5f5"
  },
  listIcon: {
    color: "#a5a5a5",
    fontSize: "19px",
  },
  listIconSelected: {
    color: "#0b1f82",
    fontSize: "19px"
  }
}));

const whitelist_translation = [
  "d5885571-a5fe-4144-9a06-879d2af94aa1", //Didi PD
  "14038e04-c887-4e4d-a0f7-52ba9e517478", //Didi SB
  '123-123-123',
  '64520138-3e5b-4c2a-856a-33e1b199b0cb', // Zaig SB
  'b81a015d-640e-4d11-8921-1ab46f168c8d' // Zaig PD
]

function SideBar(props) {
  const classes = useStyles();
  useTheme();
  const {language, setLanguage, selectedProduct, setSelectedProduct, setSelectedPage, pages, routes, allowedProducts } = props

  const { t } = useTranslation();

  let { user_data } = useContext(AuthContext)
  
  const showLanguageSelector = whitelist_translation.includes(user_data.company_key) 

  let history = useHistory()

  const handleClick = (event) => {
    const product = event.target.id
    const page = pages[product][0]
    setSelectedProduct(product)
    setSelectedPage(page)
    history.push(routes[page])
  }

  const tooltips = {
    "onboarding"           : "Crie e visualize análises antifraude de Pessoas Físicas e Jurídicas em função dos dados cadastrais.",
    "payments"             : "Cadastre seu cartão para poder utilizar as funcionalidades de plataforma.",
    "rules"                : "Crie e ative regras de antifraude para os seus serviços.",
    "lists"                : "Crie, visualize e gerencie suas listas.",
    "user_management"      : "Gerencie os usuários que tem acesso à plataforma e suas permissões.",
    "credit_analysis"      : "Crie e visualize análises de crédito de seus clientes.",
    "card_issuance"        : "Visualize as transações e alertas de emissão de cartão de seus clientes, e gerencie bloqueios.",
    "card_order"           : "Visualize os pedidos e alertas de cartão de seus clientes, e gerencie bloqueios.",
    "car_rental"           : "Crie e visualize análises de alugueis de carros, e verifique solicitações de reserva.",
    "bankslip"             : "Verifique as análises realizadas sobre boletos.",
    "bill_payment"         : "Verifique as análises realizadas sobre pagamentos de contas.",
    "wire_transfer"        : "Verifique as análises realizadas sobre transferências.",
    "withdrawal"           : "Verifique as análises realizadas sobre saques.",
    "deposit"              : "Verifique as análises realizadas sobre depósitos.",
    "operations"           : "Verifique as análises realizadas sobre operações de câmbio.",
    "dashboard"            : "Verifique suas métricas de consumo na plataforma antifraude QI Tech KYC.",
    "keys"                 : "Visualize e gerencie suas API Keys.",
    "reports"              : "Solicite diversos tipos de relatórios.",
    "settings"             : "Gerencie suas configurações.",
    "pix"                  : "Verifique as análises sobre transações, dicts e relatos PIX.",
    "alerts"               : "Verifique os alertas disparados por análises antifraude.",
    "locks"                : "Verifique e crie bloqueios para os serviços contratados.",
    "manage_integration"   : "Gerencie suas Integração para os produtos.",
    "account"              : "Gerencia suas contas monitoradas."
  }

  const icons = {
    "car_rental": <DriveEtaIcon id={"car_rental"} className={"car_rental"===selectedProduct?"listIcon active":"listIcon"}/>,
    "onboarding": <PersonIcon id={"onboarding"} className={"onboarding"===selectedProduct?"listIcon active":"listIcon"} />,
    "card_issuance": <PaymentIcon id={"card_issuance"} className={"card_issuance"===selectedProduct?"listIcon active":"listIcon"} />,
    "alerts": <NotificationsNoneIcon id={"alerts"} className={"alerts"===selectedProduct?"listIcon active":"listIcon"} />,
    "locks": <LockIcon id={"locks"} className={"locks"===selectedProduct?"listIcon active":"listIcon"} />,
    "bankslip": <img id={"bankslip"} className={"locks"===selectedProduct?"listIcon active":"listIcon"} src={"bankslip"===selectedProduct?blueBankslip:grayBankslip} alt="bankslip Logo"/>,
    "bill_payment": <img id={"bill_payment"} src={"bill_payment"===selectedProduct?blueBillPayment:grayBillPayment} alt="bill payment Logo"/>,
    "wire_transfer": <img id={"wire_transfer"} src={"wire_transfer"===selectedProduct?blueWireTransfer:grayWireTransfer} alt="wire transfer Logo"/>,
    "withdrawal": <img id={"withdrawal"} src={"withdrawal"===selectedProduct?blueWithdrawal:grayWithdrawal} alt="withdrawal Logo"/>,
    "deposit": <img id={"deposit"} src={"deposit"===selectedProduct?blueDeposit:grayDeposit} alt="deposit Logo"/>,
    "operations": <SwapHorizontalCircleIcon id={"operations"} className={"operations"===selectedProduct?"listIcon active":"listIcon"} />,
    "user_management": <PermissionIcon id={"user_management"} className={"user_management"===selectedProduct?"listIcon active":"listIcon"} />,
    "dashboard": <InsertChartIcon id={"dashboard"} className={"dashboard"===selectedProduct?"listIcon active":"listIcon"} />,
    "keys": <VpnKeyIcon id={"keys"} className={"keys"===selectedProduct?"listIcon active":"listIcon"} />,
    "card_order": <LocalMallIcon id={"card_order"} className={"card_order"===selectedProduct?"listIcon active":"listIcon"} />,
    "reports": <SummarizeIcon id={"reports"} className={"reports"===selectedProduct?"listIcon active":"listIcon"} />,
    "lists": <FormatListBulletedIcon id={"lists"} className={"lists"===selectedProduct?"listIcon active":"listIcon"} />,
    "settings": <SettingsIcon id={"settings"} className={"settings"===selectedProduct?"listIcon active":"listIcon"} />,
    "payments": <BillingIcon id={"payments"} className={"payments"===selectedProduct?"listIcon active":"listIcon"} />,
    "credit_analysis": <MonetizationOnIcon id={"credit_analysis"} className={"credit_analysis"===selectedProduct?"listIcon active":"listIcon"} />,
    "rules": <RuleIcon id={"rules"} className={"rules"===selectedProduct?"listIcon active":"listIcon"} />,
    "pix": <PixIcon id={"pix"} className={"pix"===selectedProduct?"listIcon active":"listIcon"} />,
    "manage_integration": <KeyIcon id={"manage_integration"} className={"manage_integration"===selectedProduct?"listIcon active":"listIcon"} />,
  }

  const [expanded, setExpanded] = useState(true);

  const calculateExpandedHeight = (context) => {
    let reduce = 0;

    if (['read_pix_transactions', 
         'read_pix_dict_operations', 
         'read_wire_transfers', 
         'read_withdrawals', 
         'read_bankslips', 
         'read_bill_payments',
         'read_onboarding_natural_persons',
         'read_onboarding_legal_persons',
         'manage_integration'].some(role => context.user_data.roles.includes(role))) {
      reduce = reduce + 70;
    }
    if (showLanguageSelector) {
      reduce = reduce + 30;
    }

    return `calc(100vh - ${180 + reduce}px)`
  }

  const drawer = (
    <AuthContext.Consumer>
      {context => (
        <div className="sidebar">
          <div style={{display: expanded ? "flex" : "none", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "0 16px"}}>
            <ChevronRightIcon style={{opacity: 0}}/>
            <div className="sidebarLogo">
              <img src={logoQI} alt="Logo QI2" style={{paddingBottom: "8px", height: "84px", width: "auto"}}/>
            </div>
            <ChevronRightIcon style={{cursor: "pointer", transform: "rotate(180deg)", color: "#a5a5a5", border: "2px solid #a5a5a5", borderRadius: "4px"}} onClick={() => setExpanded(!expanded)}/>
          </div>
          <div style={{display: expanded ? "none" : "flex", padding: "16px 0", flexDirection: "column", gap: "4px", alignItems: "center"}}>
            <img src={smallQI} alt="Logo QI2" style={{paddingBottom: "8px", height: "56px", width: "auto"}}/>
            <ChevronRightIcon style={{cursor: "pointer", color: "#a5a5a5", border: "2px solid #a5a5a5", borderRadius: "4px"}} onClick={() => setExpanded(!expanded)}/>
          </div>
          <nav className="sidebarItemsContainer">
            {showLanguageSelector && expanded &&
              <LanguageSelector language={language} setLanguage={setLanguage}/>
            }
            {expanded && [
              'read_pix_transactions', 
              'read_pix_dict_operations', 
              'read_wire_transfers', 
              'read_withdrawals', 
              'read_bankslips', 
              'read_bill_payments',
              'read_onboarding_natural_persons',
              'read_onboarding_legal_persons',
              'manage_integration'
            ].some(role => context.user_data.roles.includes(role)) ?
              (<SearchBar 
                id={"search_banking"}
                key={"search_banking"}
                setSelectedProduct={setSelectedProduct}
                setSelectedPage={setSelectedPage}
                />)
              : null}
              <div style={{height: expanded ? calculateExpandedHeight(context) : "calc(100vh - 180px)", overflowY: "scroll", paddingRight: 4, paddingLeft: 4}}>
                {allowedProducts
                  .map((product) => {
                    
                    if (product in tooltips) {
                      return (
                        <Tooltip key={product} title={<div className="tooltipText">{t(tooltips[product])}</div>}>
                        <div
                          id={product}
                          className={product===selectedProduct?"sidebarItems active":"sidebarItems"}
                          onClick={event => handleClick(event)}
                          style={!expanded ? {alignItems: "center", justifyContent: "center", paddingLeft: "10px"} : {}}
                        >
                          {icons[product]||<InboxIcon id={product} className={product===selectedProduct?"listIcon active":"listIcon"} />}
                          {expanded ? t(product) : null}
                        </div>
                        </Tooltip>
                      )
                    }
                    
                    return (
                      <div
                        id={product}
                        key={product}
                        className={product===selectedProduct?"sidebarItems active":"sidebarItems"}
                        onClick={event => handleClick(event)}
                      >
                        {icons[product]||<InboxIcon id={product} className={product===selectedProduct?"listIcon active":"listIcon"} />}
                        {expanded ? t(product) : null}
                      </div>
                    )})}
              </div>
          </nav>
          <div style={{flex: 1, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Profile expanded={expanded} />
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  );

  return (
    <React.Fragment>
      <nav className={classes.drawer} style={{width: expanded ? "220px" : "64px"}} aria-label="mailbox folders">
        <div
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
          style={{borderRight: "1px solid #d6d6d6"}}
        >
          {drawer}
        </div>
      </nav>
    </React.Fragment>
  );
}

export default SideBar;

import React from 'react'
import { CircularProgress } from '@material-ui/core';
import peopleIcon from "../../../../assets/cardEntryIcons/people.svg"
import AccountPersonsTable from './AccountPersonsTable/AccountPersonsTable';

function AccountPersons(props) {

    let {AccountPersons} = props
    
    if (AccountPersons === null) {
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Titulares da Conta</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <CircularProgress style={{margin:"auto"}} />
                </div>
            </div>
        );    
    }
    else if (AccountPersons === undefined){
        return (
            <div className="analysisCard" style={{minHeight: "250px"}}>
                <div className={["blueText", "subtitleSize"].join(" ")}>Titulares da Conta</div>
                <div style={{display:"flex", flexGrow:"1"}}>
                    <div className={["labelText", "normalMediumSize"].join(" ")} style={{margin:"auto"}}>
                        Informação Indisponível
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="analysisCard">
            <div style={{display: "flex"}}>
                <img className="cardTitleIconStyle" src={peopleIcon} alt="peopleIcon Logo" />
                <span className={["blueText", "subtitleSize", "labelSpace"].join(" ")}>Titulares da Conta</span>
            </div>
            <div className="internalCardContainer">
                <div>
                    <div>
                        <AccountPersonsTable account_persons={AccountPersons}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountPersons